import Vue from "vue";
import App from "./page/App.vue";
import HtmlToPDF from "./components/htmlToPDF";
import veform from "@verified/veform";
import Multiselect from "vue-multiselect";

Vue.config.productionTip = false;

import "./page/style.scss";


HtmlToPDF.install(Vue);
Vue.use(veform);
Vue.component("multiselect", Multiselect);

new Vue({
  render: (h) => h(App),
}).$mount("#app");
