<template>
  <div class="pdfPreview" @click="close">
    <div class="paper" @click="dontClose">
      <HtmlToPDF ref="htmlToPDF" :copyDataFrom="data"></HtmlToPDF>
      <div class="buttons">
        <button
          type="button"
          class="btn btn-rounded btn-primary"
          @click="close"
        >
          {{ __("close") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HtmlToPDF from "@/components/htmlToPDF/HtmlToPDF.vue";
export default {
  name: "pdfPreview",
  components: {
    HtmlToPDF,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      shouldClose: true,
    };
  },
  methods: {
    close(event) {
      if (this.shouldClose) {
        this.$emit("close");
      }
      this.shouldClose = true;
    },
    dontClose() {
      this.shouldClose = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/page/style.scss";

.pdfPreview {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(94, 94, 94, 0.4);
  backdrop-filter: blur(2px);

  .paper {
    margin: 10vh auto;
    width: 80%;
    padding: 2rem 4rem;
    background-color: white;
    box-shadow: 5px 10px 18px #888888;

    p {
      font-size: 1rem;
      padding-top: 1rem;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;

      button {
        margin: 1rem;
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .paper {
      width: 95%;
      padding: 1.5rem 2rem;

      .buttons button {
        margin: 1.5rem 0.2rem 0.5rem 0.2rem;
      }
    }
  }
}
</style>
