export default {
  name: "ve-html-to-pdf",
  props: {
    copyDataFrom: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    let obj = {
      cssNodes: [],
      pdfStyle: null
    }
    if ( this.copyDataFrom && this.copyDataFrom._data ) {
      for ( let li_i in this.copyDataFrom._data ) {
        let li = this.copyDataFrom._data[ li_i ]
        obj[ li_i ] = li
      }
    }
    return obj
  },
  methods: {
    onCreated () {
      this.generateCssFromJs()
    },
    onMounted () { },
    copyDataFromProp () {
      // has be run if data has been changed
      if ( this.copyDataFrom && this.copyDataFrom._data ) {
        for ( let li_i in this.copyDataFrom._data ) {
          let li = this.copyDataFrom._data[ li_i ]
          this[ li_i ] = li
        }
      }
    },
    sheetToHtml ( value ) {
      let self = this
      value = value.replace( /\n/g, '<br>' )

      value = value.replace( /{{[^\}]+}}/g, function ( x ) {
        try {
          let props = x.replace( /{|}/g, '' ).split( '.' )
          let val = self
          for ( let i = 0; i < props.length; i++ ) {
            let li = props[ i ]
            val = val[ li ]
          }
          return val ? val + '' : x
        } catch ( err ) {
          err.message = x + ' value is missing ' + err.message
          console.log( err )
        }
      } )

      return value
    },
    async generateCssFromJs () {
      this.pdfCss = await fetch( 'pdf.css' ).then( res => res.text() )

      let styles = document.querySelectorAll( 'style' ) // local
      let links = document.querySelectorAll( 'link[rel="stylesheet"]' ) // prod
      for ( let i = 0; i < styles.length; i++ ) {
        let cssNode = styles[ i ]
        this.cssNodes.push( {
          node: cssNode,
          parentNode: cssNode.parentNode
        } )
      }
      for ( let i = 0; i < links.length; i++ ) {
        let cssNode = links[ i ]
        this.cssNodes.push( {
          node: cssNode,
          parentNode: cssNode.parentNode
        } )
      }

      this.pdfStyle = document.createElement( 'style' )
      this.pdfStyle.textContent = this.pdfCss

      this.reorderPdfStyles()
    },
    generateHtmlForPDF () {
      this.copyDataFromProp()
      this.ud.html = [
        '<style>',
        this.pdfCss,
        '</style>',
        this.$refs.pdf_content.innerHTML
      ].join( '' )
    },
    reorderPdfStyles () {
      if ( this.displayPDF ) {
        if ( !document.head.contains( this.pdfStyle ) ) {
          document.head.appendChild( this.pdfStyle )
        }
        for ( let i = 0; i < this.cssNodes.length; i++ ) {
          let cssNode = this.cssNodes[ i ]
          if ( cssNode.parentNode.contains( cssNode.node ) ) {
            cssNode.parentNode.removeChild( cssNode.node )
          }
        }
      } else {
        if ( document.head.contains( this.pdfStyle ) ) {
          document.head.removeChild( this.pdfStyle )
        }
        for ( let i = 0; i < this.cssNodes.length; i++ ) {
          let cssNode = this.cssNodes[ i ]
          if ( !cssNode.parentNode.contains( cssNode.node ) ) {
            cssNode.parentNode.appendChild( cssNode.node )
          }
        }
      }
    },
  },
  computed: {
    today () {
      let d = new Date()
      return d.toISOString().split( "T" )[ 0 ];
    },
  },
  watch: {
    loading () {
      this.copyDataFromProp()
    },
    displayPDF () {
      this.reorderPdfStyles()
    },
  },
  created () {
    this.onCreated()
  },
  mounted () {
    this.onMounted()
  }
}