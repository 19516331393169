import axios from "axios";
import { auth, envelopes, bisnode } from "@verified/libvf";
import veform from "@verified/veform";
import PdfPreview from "@/components/PdfPreview.vue";

export default {
  name: "app",
  components: {
    PdfPreview,
  },
  data() {
    return {
      testing: false,
      displayPDF: false,
      submitRAF: undefined,
      canSubmit: undefined,
      loading: true,
      showLoader: false,
      years: [],
      positions: [],
      bankList: [
        "Amfa Bank",
        "Avanza Bank",
        "Bluestep Finans",
        "BNP Paribas Fortis",
        "Citibank International Plc, Sweden Branch",
        "Danske Bank A/S",
        "Danske Bolån",
        "DNB Bank ASA",
        "Forex Bank AB",
        "Handelsbanken",
        "ICA Banken",
        "IKANO Banken",
        "Länsförsäkringar Bank",
        "Lån og Spar Bank Sverige",
        "Marginalen Bank",
        "Nordax Bank AB",
        "Nordea",
        "Nordea - Personkonto",
        "Nordea - PlusGirot",
        "Nordnet Bank",
        "Resurs Bank AB",
        "Royal Bank of Scotland",
        "Santander Consumer Bank AS",
        "SBAB",
        "SEB",
        "Skandiabanken AB",
        "Sparbanken Syd",
        "Sparbanken Öresund",
        "Swedbank – clearingnr 7000 – 7999",
        "Swedbank – clearingnr 80000 – 89999",
        "Ålandsbanken Abp (Finland), svensk filial",
      ],
      bankListJSON: {
        "Amfa Bank": {
          text: "Amfa Bank",
          clearNoFormat: "9660-9669",
          "data-account": "7",
          clearPattern: /^966[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Avanza Bank": {
          text: "Avanza Bank",
          clearNoFormat: "9550-9569",
          "data-account": "7",
          clearPattern: /^955[0-9]|956[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Bluestep Finans": {
          text: "Bluestep Finans",
          clearNoFormat: "9680-9689",
          "data-account": "7",
          clearPattern: /^968[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "BNP Paribas Fortis": {
          text: "BNP Paribas Fortis",
          clearNoFormat: "9470-9479",
          "data-account": "7",
          clearPattern: /^947[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Citibank International Plc, Sweden Branch": {
          text: "Citibank International Plc, Sweden Branch",
          clearNoFormat: "9040-9049",
          "data-account": "7",
          clearPattern: /^904[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Danske Bank A/S": {
          text: "Danske Bank A/S, Sverige Filial",
          clearNoFormat: "1200-1399,2400-2499",
          "data-account": "7",
          clearPattern: /^120[0-9]|12[1-9][0-9]|13[0-9]{2}|240[0-9]|24[1-9][0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Danske Bolån": {
          text: "Danske Bolån",
          clearNoFormat: "1200-1399",
          "data-account": "7",
          clearPattern: /^120[0-9]|12[1-9][0-9]|13[0-9]{2}$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "DNB Bank ASA": {
          text: "DNB Bank ASA, filial Sverige",
          clearNoFormat: "9190-9199",
          "data-account": "7",
          clearPattern: /^919[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Forex Bank AB": {
          text: "Forex Bank AB",
          clearNoFormat: "9400-9449",
          "data-account": "7",
          clearPattern: /^940[0-9]|94[1-4][0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        Handelsbanken: {
          text: "Handelsbanken",
          clearNoFormat: "6000-6999",
          "data-account": "7,9",
          clearPattern: /^600[0-9]|60[1-9][0-9]|6[1-9][0-9]{2}$/,
          accPattern: "^[0-9]{7,9}$",
          accNoFormat: "Max 9 siffror",
        },
        "ICA Banken": {
          text: "ICA Banken",
          clearNoFormat: "9270-9279",
          "data-account": "7",
          clearPattern: /^927[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "IKANO Banken": {
          text: "IKANO Banken",
          clearNoFormat: "9170-9179",
          "data-account": "7",
          clearPattern: /^917[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Länsförsäkringar Bank": {
          text: "Länsförsäkringar Bank",
          clearNoFormat: "9020-9029,3400-3409,9060-9069",
          "data-account": "7",
          clearPattern: /^902[0-9]|340[0-9]|906[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Lån og Spar Bank Sverige": {
          text: "Lån og Spar Bank Sverige",
          clearNoFormat: "9630-9639",
          "data-account": "7",
          clearPattern: /^963[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Marginalen Bank": {
          text: "Marginalen Bank",
          clearNoFormat: "9230-9239",
          "data-account": "7",
          clearPattern: /^923[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Nordax Bank AB": {
          text: "Nordax Bank AB",
          clearNoFormat: "9640-9649",
          "data-account": "7",
          clearPattern: /^964[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        Nordea: {
          text: "Nordea",
          clearNoFormat:
            "1100 - 1199,1401 - 2099,3000 - 3029,3031 - 3399,3410 - 4999",
          "data-account": "7",
          clearPattern: /^110[0-9]|11[1-9][0-9]|140[1-9]|14[1-9][0-9]|1[5-9][0-9]{2}|20[0-9]{2}|300[0-9]|30[12][0-9]|303[1-9]|30[4-9][0-9]|3[1-3][0-9]{2}|341[0-9]|34[2-9][0-9]|3[5-9][0-9]{2}|4[0-9]{3}$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Nordea - Personkonto": {
          text: "Nordea - Personkonto",
          clearNoFormat: "3300",
          "data-account": "10",
          clearPattern: /^3300$/,
          accPattern: "^[0-9]{10}$",
          accNoFormat: "10 siffror",
        },
        "Nordea - PlusGirot": {
          text: "Nordea - PlusGirot",
          clearNoFormat: "9500-9549,9960-9969",
          "data-account": "10",
          clearPattern: /^950[0-9]|95[1-4][0-9]|996[0-9]$/,
          accPattern: "^[0-9]{10}$",
          accNoFormat: "10 siffror",
        },
        "Nordnet Bank": {
          text: "Nordnet Bank",
          clearNoFormat: "9100-9109",
          "data-account": "7",
          clearPattern: /^910[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Resurs Bank AB": {
          text: "Resurs Bank AB",
          clearNoFormat: "9280-9289",
          "data-account": "7",
          clearPattern: /^928[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Royal Bank of Scotland": {
          text: "Royal Bank of Scotland",
          clearNoFormat: "9090-9099",
          "data-account": "7",
          clearPattern: /^909[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Santander Consumer Bank AS": {
          text: "Santander Consumer Bank AS",
          clearNoFormat: "9460-9469",
          "data-account": "7",
          clearPattern: /^946[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        SBAB: {
          text: "SBAB",
          clearNoFormat: "9250-9259",
          "data-account": "7",
          clearPattern: /^925[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        SEB: {
          text: "SEB",
          clearNoFormat: "5000-5999,9120-9124,9130-9149",
          "data-account": "7",
          clearPattern: /^500[0-9]|50[1-9][0-9]|5[1-9][0-9]{2}|912[0-4]|913[0-9]|914[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Skandiabanken AB": {
          text: "Skandiabanken AB",
          clearNoFormat: "9150-9169",
          "data-account": "7",
          clearPattern: /^915[0-9]|916[0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Sparbanken Syd": {
          text: "Sparbanken Syd",
          clearNoFormat: "9570-9579",
          "data-account": "10",
          clearPattern: /^957[0-9]$/,
          accPattern: "^[0-9]{10}$",
          accNoFormat: "10 siffror",
        },
        "Sparbanken Öresund": {
          text: "Sparbanken Öresund",
          clearNoFormat: "9300-9349",
          "data-account": "10",
          clearPattern: /^930[0-9]|93[1-4][0-9]$/,
          accPattern: "^[0-9]{10}$",
          accNoFormat: "10 siffror",
        },
        "Swedbank – clearingnr 7000 – 7999": {
          text: "Swedbank – clearingnr 7000 – 7999",
          clearNoFormat: "7000-7999",
          "data-account": "7",
          clearPattern: /^700[0-9]|70[1-9][0-9]|7[1-9][0-9]{2}$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
        "Swedbank – clearingnr 80000 – 89999": {
          text: "Swedbank – clearingnr 80000 – 89999",
          clearNoFormat: "80000-89999",
          "data-account": "10",
          clearPattern: /^8000[0-9]|800[1-9][0-9]|80[1-9][0-9]{2}|8[1-9][0-9]{3}$/,
          accPattern: "^[0-9]{10}$",
          accNoFormat: "10 siffror",
        },
        "Ålandsbanken Abp (Finland), svensk filial": {
          text: "Ålandsbanken Abp (Finland), svensk filial",
          clearNoFormat: "2300-2399",
          "data-account": "7",
          clearPattern: /^230[0-9]|23[1-9][0-9]$/,
          accPattern: "^[0-9]{7}$",
          accNoFormat: "7 siffror",
        },
      },
      clearRegex: null,
      accRegex: null,
      expiresAfter: "",
      ud: {
        notifyEmployeeEditor: "",
        notifySigner: "",
        documentSignedEmployee: "",
        principalFirstName: "",
        principalLastName: "",
        principalEmail: "",
        employee: {
          ssn: "",
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          address: "",
          zip: "",
          county: "",
        },
        selectedPosition: "",
        employment: {
          probationaryEmployment: false,
          generalFixedEmployment: false,
          temporaryEmployment: false,
          phdEmployment: false,
          hourlyEmployment: false,
          permanentEmployment: false
        },
        typeOfEmployment: "",
        period: "",
        dateFrom: "",
        dateTo: "",
        manager: "",
        costCenter: "",
        projectNumber: "",
        employmentRate: {
          fullTime: false,
          partTime: false,
        },
        rate: undefined,
        partTimeSalary: "",
        workTime: {
          one: false, 
          two: false
        },
        vacation: {
          one: false,
          two: false
        },
        salary: undefined,
        revisonYear: "",
        salaryHour: "",
        bank: "",
        clearNo: "",
        accountNo: "",
        taxTable: "",
        attachments: [],
        // GENERAL
        fileName: "",
        _isForwarded: false,
        archive: "",
        redirectTo: "",
        tacInfo : {
          yes : false
        }
      },
      validPersonNumber: false,
      descriptor: "redcross-school-empl-contract",
      flow: "/flows/simple-public-action-flow",
      public: false,
      lang: "sv_SE",
      translations: {},
      config: {},
      envelope: undefined,
      user: undefined,
      role: "owner",
      readyForSign: false,
    };
  },
  methods: {
    // TRANSLATIONS & CONFIG
    __(key) {
      // Return translation for given key
      if (this.translations[key]) return this.translations[key][this.lang];
    },

    __config(key) {
      // Return value from config
      if (this.config[key]) return this.config[key].value;
    },
 

    // ON CREATED
    async onCreated() {
      this.$root.locale = this.lang;
      this.setIso(this.$root.locale);
      this.$forceUpdate();

      try {
        this.loading = true;
        await this.getSheetData();
        let document_uid = this.getUrlParameter("document_uid") || false;
        await this.getYears()
        if (document_uid) {
          // = Is editor
          await this.getEnvelopeData();
          auth.setNamespace(this.envelope.data.sender.namespace); // x-namespace for bisnode lookup
        } else {
          this.authenticate(this.ud.source);
          await this.getUser();
        }

      } catch (e) {
        console.error(e);
        this.exceptions.push(
          "Unable to get external data. Try refreshing your browser."
        );
      }
      if (this.testing) {
        this.injectTestData();
      }
      this.loading = false;
    },
    changePattern() {
      this.clearRegex = this.bankListJSON[this.ud.bank].clearPattern;
      this.accRegex = this.bankListJSON[this.ud.bank].accPattern;
    },

    // GET DATA
    async getSheetData() {
      const [translations, positions, config] = await Promise.all([
        axios.get(
          "https://sheets.web-services.verified.eu/" +
            this.descriptor +
            "/translations"
        ),
        axios.get(
          "https://sheets.web-services.verified.eu/" +
            this.descriptor +
            "/positions"
        ),
        axios.get(
          "https://sheets.web-services.verified.eu/" +
            this.descriptor +
            "/config"
        ),
      ]);

      this.translations = translations.data;
      this.config = config.data;
      document.title = this.config.tabTitle[this.lang];
      this.expiresAfter = this.config.expiresAfter[this.lang]
      this.ud.redirectTo = this.config.redirectTo[this.lang]
      this.ud.principalFirstName = this.config.principalFirstName[this.lang]
      this.ud.principalLastName = this.config.principalLastName[this.lang]
      this.ud.principalEmail = this.config.principalEmail[this.lang]
      this.ud.notifyEmployeeEditor = this.config.notifyEmployeeEditor[this.lang]
      this.ud.notifySigner = this.config.notifySigner[this.lang]
      this.ud.documentSignedEmployee = this.config.documentSignedEmployee[this.lang]

      // mergeLocale combines veform's default translations with the ones from sheets duplicates are overwritten by the sheet
      this.mergeLocale(this.translations);
      
		  let i = 0;
			for (let positions_i in positions.data) {
				let key = positions.data[positions_i]	
				if (key.position) {
					this.positions.push(key.position);
				}
				i++;
			}

      let cover = document.getElementById("cover"); 
		  if(this.config){ 
		  	cover.style.backgroundImage = `url(${this.config.background[this.lang]})`
		  	if(this.config.primary_color&&this.config.primary_color[this.lang]) {
		  		document.documentElement.style.setProperty('--primary', this.config.primary_color[this.lang])
		  	}
		  } 
      veform.addCustomValidation("clearRegex", (value, constraint) => {
        let status = self.clearRegex ? self.clearRegex.test(value) : false;
        return { status: status, key: "validation.clearRegex" };
      });
      veform.addCustomValidation("accRegex", (value, constraint) => {
        let status = self.accRegex ? self.accRegex.test(value) : false;
        return { status: status, key: "validation.accRegex" };
      });
      // this.mergeLocale({
      //   "validation.orgnrse": {
      //     sv_SE: this.translations.invalidPersOrgNo["sv_SE"],
      //     en_EN: this.translations.invalidPersOrgNo["en_EN"],
      //   },
      //   "validation.pnrse": {
      //     sv_SE: this.translations.invalidPersNo["sv_SE"],
      //     en_EN: this.translations.invalidPersNo["en_EN"],
      //   }
      // });
    },
    async getYears(){   
		  let current = new Date().getFullYear()
			let max = current + 1
			for (let i = current; i <= max; i++) {
			  this.years.push(`${i}`)
			}
    },

    async getPartTimeSalary(parseSalary, parsedRate){
        let rate = this.ud.rate
        let salary = 0;

        if(this.ud.salary){
          salary = this.ud.salary.replace(/ /g,'');
        }

        parseSalary = parseInt(salary, 10)
        parsedRate = parseInt(rate, 10)
        
        let partTimeSalary = (parseSalary/100) * parsedRate
  
        this.ud.partTimeSalary = partTimeSalary;
      //  console.log("partTimeSalary ===============",partTimeSalary);
        return partTimeSalary  
   },

    // BISNODE
    async lookup() {
        let ud = this.ud;
        this.showLoader = true;
        try {
          let personInfo = await bisnode.sweden.getPersonInfo(ud.employee.ssn);
          let consumer = personInfo.consumer;
          console.log(personInfo.consumer);
          if (consumer.firstName) {
            ud.employee.firstName = consumer.firstName;
          }
          if (consumer.surname) {
            ud.employee.lastName = consumer.surname;
          }
          if (consumer.postalAdressLine1) {
            ud.employee.address = consumer.postalAdressLine1;
          }
          if (consumer.postCode) {
            ud.employee.zip = consumer.postCode;
          }
          if (consumer.town) {
            ud.employee.county = consumer.town;
          }
        } catch (e) {
          console.log(e);
        }
        this.showLoader = false;
        this.personInfoReturned = true;
        this.notifySigner;
    },

    getUrlParameter(param, dummyPath) {
      let sPageURL = dummyPath || window.location.search.substring(1),
        sURLVariables = decodeURIComponent(sPageURL).split(/[&||?]/),
        res;
      for (let i = 0; i < sURLVariables.length; i += 1) {
        let paramName = sURLVariables[i],
          sParameterName = (paramName || "").split("=");
        if (sParameterName[0] === param) {
          res = sParameterName[1];
        }
      }
      return res;
    },

    async authenticate() {
      this.ud.archive = this.getUrlParameter("c");
      auth.authenticate({
        token: this.getUrlParameter("access_token"),
        namespace: "/companies/" + this.ud.archive,
      });
    },

    async getUser() {
      this.user = await auth.getUserinfo();
    },

    async getEnvelopeData() {
      this.loading = true;
      auth.useTokenFromUrl();
      this.role = auth.role;
      this.envelope = await envelopes.getFromUrl();

      if (localStorage.getItem(this.envelope.id)) {
        let signUrl = localStorage.getItem(this.envelope.data.id);
        window.location.href = signUrl;
      }
      let data = (this.data = await this.envelope
        .firstTemplate()
        .getUserData());
      this.ud = { ...this.ud, ...data };
    },

    // LISTS AND FILES
    // addListObject(list, object) {
    //   this.ud[list].push(object);
    // },

    // removeListObject(list, index) {
    //   this.ud[list].splice(index, 1);
    // },

    // chooseFiles(ref) {
    //   this.$refs[ref].click();
    // },

    // updateFiles(e, fileList) {
    //   let files = [];
    //   for (let i = 0; i < e.target.files.length; i++) {
    //     files.push({
    //       file: e.target.files[i],
    //       fileName: e.target.files[i].name,
    //     });
    //   }
    //   this.ud[fileList].push.apply(this.ud[fileList], files);
    // },

    // removeFile(index, fileList) {
    //   this.ud[fileList].splice(index, 1);
    // },

    // SUBMIT
   
    async validateSubmit() {
      let self = this;
      cancelAnimationFrame(this["submitRAF"]);
      this["submitRAF"] = requestAnimationFrame(() => {
        let form = self.$refs.form;
        self.canSubmit = form ? form.checkValidity() : false;
      });
    },

    async createEnvelope() {
      let envelope = await envelopes.create(this.descriptor);
      const bearerToken = await envelope.getBearerToken(this.flow);
      auth.setToken(bearerToken);
      this.envelope = envelope;
    },

    async addRecipients(envelope) {
      let ud = this.ud;
      await envelope.addRecipient({
        givenName: ud.employee.firstName || " ",
        familyName: ud.employee.lastName || " ",
        email: ud.employee.email,
        language: this.lang,
        order: 1,
        role: {
          action: "edit",
          name: "editor",
        },
      });
      await envelope.addRecipient({
        givenName: ud.principalFirstName || " ",
        familyName: ud.principalLastName || " ",
        email: ud.principalEmail,
        language: this.lang,
        signingMethod: "bankid-se",
        order: 2,
        role: {
          action: "sign",
          name: "signer",
        },
      });
      await envelope.addRecipient({
        givenName: ud.employee.firstName || " ",
        familyName: ud.employee.lastName || " ",
        email: ud.employee.email,
        language: this.lang,
        signingMethod: "bankid-se",
        order: 3,
        role: {
          action: "sign",
          name: "signer",
        },
      });
    },

    async addSilentParties(envelope) {
      let silentParties = [{
            givenName: this.ud.employee.givenName,
            familyName: this.ud.employee.familyName,
            email: this.ud.employee.email,
            emailTemplate: '/document-signed-employee.marko',
          },
          {
            givenName: this.user.givenName,
            familyName: this.user.familyName,
            email: this.user.email,
            emailTemplate: '/document-signed.marko',
          }
      ];
       await envelope.put({ 
        metadata : {
          silentParties :silentParties,
          noDocumentSignedMails: true,
        }
      })
    },

    async addEnvelopeSettings(envelope) {
      let automaticReminders;
      let expirationDate;
      if (automaticReminders || expirationDate) {
        await envelope.put({
          automaticReminders: +this.__config("automaticReminder"),
          expiration: this.expirationDate,
        });
      }
    },

    async setUserData(envelope) {
      this.ud._isForwarded = true; // _isForwarded.true triggers the notify signatory email
      await envelope.firstTemplate().setUserData(this.ud);
    },

    getFileName() {
      let ud = this.ud;
      ud.fileName = [ud.employee.firstName, " ", ud.employee.lastName, " anställningsavtal",  ".pdf"].join("");
    },

    async setFileName(envelope) {
      this.getFileName();
      await envelope.firstDocument().put({
        name: this.ud.fileName,
      });
    },

    getTags() {
      let tags = [];
      return tags;
    },

    async setTags(envelope) {
      let tags = [];
      tags.push(this.getTags());
      if (tags.length) {
        let i = 0;
        for (let tags_i in tags) {
          let tag = tags[tags_i];
          await envelope.firstDocument().setTags(tag);
          i++;
        }
      }
    },

    // async uploadAttachments(envelope) {
    //   let ud = this.ud;
    //   if (ud.attachments.length > 0) {
    //     for (let i = 0; i < ud.attachments.length; i++) {
    //       (async function() {
    //         let file = ud.attachments[i].file;
    //         let fileName = ud.attachments[i].fileName;
    //         await envelope.firstDocument().uploadAttachment(fileName, file);
    //       })();
    //     }
    //   }
    // },

    setReadyForSign() {
        this.readyForSign = true;
        this.ud._isForwarded = true; // _isForwarded.true triggers the notify signatory email
    },

    async redirect(envelope) {
      if (!this.public && this.role === "owner") {
        window.location.href =
          "https://app.verified.eu/#/dashboard?companyId=" + this.ud.archive;
      } else {
        // if (this.readyForSign) {
        //   await envelope.reflect();
        //   const signToken = await envelope.getSignToken(this.flow);
        //   const signUrl = `https://app.verified.eu/#/sign/envelopes/${
        //     envelope.data.id
        //   }?access_token=${signToken}&redirect_to=${encodeURIComponent(
        //     this.ud.redirectTo
        //   )}`;
        //   window.location.href = signUrl;
        // } else {
          window.location.href = this.ud.redirectTo;
        // }
      }
    },

    async preSubmit() {
      // Generates HTML && CSS for PDF
      let self = this;
      this.$refs.htmlToPDF.copyDataFromProp();
      requestAnimationFrame(function() {
        self.$refs.htmlToPDF.generateHtmlForPDF();
        return self.submit();
      });
    },

    async submit() {
      this.showLoader = true;
      this.setReadyForSign();
      try {
        if (this.role === "owner") {
          await this.createEnvelope();
          let envelope = this.envelope;
          await envelope.reflect();
          await this.addRecipients(envelope);
          await this.addSilentParties(envelope);
          await this.addEnvelopeSettings(envelope);
          await this.setUserData(envelope);
          await this.setFileName(envelope);
          await this.setTags(envelope);
        // await this.uploadAttachments(envelope);

        // Publish envelope
          await envelope.reflect();
          await envelope.publish();
        }else if(this.role === "editor"){
          await this.setUserData(this.envelope);
        }

        // Redirect user
        this.redirect(this.envelope);
      } catch (e) {
        console.error(e);
      }
    },

    // TESTING
    injectTestData() {
      let ud = this.ud;
      // Card one
      ud.employee.email = "anna.verified@gmail.com";
      ud.employee.firstName = "Anna"
      ud.employee.lastName = "Zetterström"
      ud.employee.ssn = "950411-4282"
      ud.employee.phone = "0700373005"
      ud.employee.address = "Testvägen 4"
      ud.employee.zip = "120 32"
      ud.employee.county = "Stockholm"
      //card two
      ud.selectedPosition = "Lärare"
      ud.employment.probationaryEmployment = true
      ud.employment.generalFixedEmployment = false
      ud.employment.temporaryEmployment = false
      ud.employment.phdEmployment = false
      ud.employment.hourlyEmployment = false
      // ud.typeOfEmployment = "test"
      // ud.period = "1 och 2"
      ud.dateFrom = "2021-03-08"
      ud.dateTo = "2021-12-03"
      ud.manager = "Attesteratnde chef"
      ud.costCenter = "Kostcenter"
      ud.projectNumber = "12"

      //card three
      ud.employmentRate.fullTime = true
      ud.employmentRate.partTime = false
      // ud.rate = "12"
      ud.workTime.one = true
      ud.workTime.two = false
      //card four
      ud.vacation.one = false
      ud.vacation.two = true
      // card five
      ud.salary = "100"
      ud.revisonYear = "2021"

    },
  },
  computed: {
    yesterday() {
      let today = new Date();
      let yesterday = today.setDate(today.getDate() - 1);
      return new Date(yesterday);
    },
    expirationDate() {
			var today = new Date();
			let expirationDate = today.setDate(
				today.getDate() + parseInt(this.expiresAfter)
			);

			return new Date(expirationDate).toISOString();
		},

  },
  watch: {
    ud: {
      handler() {
        this.validateSubmit();
      },
      deep: true,
    },
    "ud.employee.ssn"() {
      if(this.role == 'owner'){
        this.validPersonNumber = this.$refs.personNumber.$refs.field.checkValidity();

      }
    },
    validPersonNumber() {
      if (this.validPersonNumber) {
        this.lookup();
      }
    },
    "ud.salary"(){
      if(this.ud.employmentRate.partTime){
        this.getPartTimeSalary()
      }
    },
    "ud.rate"(){
      if(this.ud.employmentRate.partTime){
        this.getPartTimeSalary()
      }
    },
    exceptions: function (newVal) {
      if (newVal.length) this.$emit("exception", newVal[newVal.length - 1]);
    },
  },
  created() {
    this.onCreated();
  },
};
